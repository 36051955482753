

const customers = (state=[], action) => {
  switch(action.type) {
    case "GET_CUSTOMERS_SUCCESS":
      return [...action.payload];

    case "GET_CUSTOMER_SUCCESS":
      return [...state, action.payload];

    case "UPDATE_CUSTOMER_SUCCESS":
      let updatedCustomer = action.payload;
      let filtered = state.filter(c => c.id !== updatedCustomer.id);
      return [...filtered, updatedCustomer];

    default:
      return state;
  }
}

export default customers;

export const getCustomers = (state) => state.customers.customers;

export const getCustomer = (state, id) => state.customers.customers.find(c => String(c.id) === String(id));
