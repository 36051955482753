import _ from 'lodash';

const defState = {
  byId: {},
  allIds: [],
  timeSlotsById: {},
  eventsById: {},
  loading: false,
  error: null,
}
export default (state, action) => {
  state =  state ? state : defState;

  switch(action.type) {
    case "FETCH_SCHEDULE_REQUEST": {
      return {...state, loading: true, error: null};
    }

    case "FETCH_SCHEDULE_FAILURE": {
      return {...state, loading: false, error: action.payload};
    }

    case "FETCH_SCHEDULE_SUCCESS": {
      return {
        byId: extractCollection(state.byId, action, "schedules"),
        allIds: extractIds(state.allIds, action, "schedules"),
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots"),
        eventsById: extractCollection(state.eventsById, action, "events"),
        loading: false,
      };
    }

    case "FETCH_SCHEDULE_DATE_REQUEST": {
      return {...state, loading: true, error: null};
    }

    case "FETCH_SCHEDULE_DATE_FAILURE": {
      return {...state, loading: true, error: action.payload};
    }

    case "FETCH_SCHEDULE_DATE_SUCCESS": {
      return {
        byId: extractCollection(state.byId, action, "schedules"),
        allIds: extractIds(state.allIds, action, "schedules"),
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots"),
        eventsById: extractCollection(state.eventsById, action, "events"),
        loading: false,
        error: null,
      };
    }

    case "UPDATE_SCHEDULE_DATE_SUCCESS": {
      return {
        byId: extractCollection(state.byId, action, "schedules"),
        allIds: extractIds(state.allIds, action, "schedules"),
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots"),
        eventsById: extractCollection(state.eventsById, action, "events"),
        loading: false,
        error: null,
      }
    }

    case "ADD_SCHEDULE_DATES_SUCCESS": {
      return {
        byId: extractCollection(state.byId, action, "schedules"),
        allIds: extractIds(state.allIds, action, "schedules"),
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots"),
        eventsById: extractCollection(state.eventsById, action, "events"),
        loading: false,
        error: null,
      }
    }

    case "ADD_TIMESLOTS_SUCCESS": {
      // group new time slot ids by the schedule they belong to
      let timeSlotIds = getEntityIds(action, "timeSlots");
      let timeSlotEntities = getEntities(action, "timeSlots");
      let groupedTimeSlots = _.groupBy(timeSlotIds, (tsId) => timeSlotEntities[tsId].scheduleId);

      // Update timeSlots of schedules
      let byId = {...state.byId};
      for(var scheduleId in groupedTimeSlots) {
        let schedule = byId[scheduleId];
        let timeSlots = groupedTimeSlots[scheduleId];
        // if schedule has existing timeSlots then join and remove duplicate ids
        if(schedule.timeSlots){
          timeSlots = _.uniq(schedule.timeSlots.concat(timeSlots));
        }
        byId[scheduleId] = {
          ...byId[scheduleId],
          timeSlots:timeSlots
        }
      }

      return {
        ...state,
        byId:byId,
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots")
      }
    }

    case "UPDATE_SCHEDULE_DATE_TIMESLOTS_SUCCESS": {
      return {
        ...state,
        timeSlotsById: extractCollection(state.timeSlotsById, action, "timeSlots")
      }
    }

    case "EXPORT_TABLES_REQUEST": {
      return {
        ...state,
        scheduleBeingExported: action.meta.scheduleId,
      }
    }

    case "EXPORT_TABLES_SUCCESS": {
      let schedule = state.byId[state.scheduleBeingExported];
      if(!schedule)
        return state;

      schedule = {
        ...schedule,
        tableExportStatus: action.payload.status,
        primaryPM: action.payload.primaryPM
      };

      return {
        ...state,
        byId: {...state.byId, [schedule.id]: schedule},
      };
    }

    case "TABLE_EXPORT_STATUS_CHANGED": {
      let schedule = state.byId[action.payload.scheduleId];
      if(!schedule)
        return state;

      schedule = {
        ...schedule,
        tableExportStatus: action.payload.status,
        primaryPM: action.payload.primaryPM
      };

      return {
        ...state,
        byId: {...state.byId, [schedule.id]: schedule},
      };
    }

    default:
      return state;
  }
}

// Selectors

export const getAllSheduledDates = (state) => {
  let dates = state.schedule.dates;
  return dates.allIds.map(id => getDateById(state, id));
}

export const getScheduledDatesByEventId = (state, eventId) => {
  let {allIds, byId} = state.schedule.dates;

  return allIds
    .filter(id => {
      return byId[id] && String(byId[id].eventId) === String(eventId)
    })
    .map(id => getDateById(state, id));
}

export const getDateById = (state, scheduleId) => {
  let dates = state.schedule.dates;
  let d = dates.byId[scheduleId];

  if(d == null  )
    return null;

  return {
    ...d,
    // Dates dont always have time slots
    timeSlots: d.timeSlots ? d.timeSlots.map(tsId => dates.timeSlotsById[tsId]) : null,
    event: dates.eventsById[d.eventId]
  }
}

export const isLoadingSchedules = (state) => state.schedule.dates.loading;
export const getScheduleError = (state) => state.schedule.dates.error;

// Helpers

function extractCollection(state, action, collectionName) {
  if(!actionHasEntities(action, collectionName))
    return state;

  let entities = getEntities(action, collectionName);
  let newState = {...state};
  for (var id in entities) {
    // if already exists merge new instance over old one
    if (newState[id]) {
      newState[id] = {...newState[id], ...entities[id]}
    } else {
      newState[id] = entities[id];
    }
  }
  return newState;
}

function extractIds(state, action, collectionName) {
  return _.uniq([
    ...state,
    ...getEntityIds(action,collectionName),
  ]);
}

export const actionHasEntities = (action, collectionName) => {
  return action.payload!=null
      && action.payload.entities != null
      && typeof action.payload.entities[collectionName] == 'object';
}

const getEntities = (action, collectionName) => {
  return action.payload.entities[collectionName];
}

export const getEntityIds = (action, collectionName) => {
  let res = action.payload.result;

  // return as array for single result case
  if(typeof res=='string' || typeof res=="number")
    return [res];

  if(Array.isArray(res)){
    return res;
  }

  // presume its an Object and get collection name from it
  return res[collectionName];
}
