
const ticketOptions = (state, action) => {
  if(!state) {
    state = {options:[], newTicketOptionLoading:false}
  }

  switch(action.type) {
    case 'GET_TICKET_OPTIONS_REQUEST':
      return {...state, loadingTicketOptions: true};
    case 'GET_TICKET_OPTIONS_SUCCESS':
      return {
        ...state,
        options: action.payload,
        loadingTicketOptions: false,
      }
    default:
      return state;
  }
}

export default ticketOptions;

export function getTicketOptions(state) {
  let options =  state.events.ticketOptions.options;
  return options || [];
}

export function isLoadingTicketOptions(state) {
  return state.events.ticketOptions.loadingTicketOptions;
}
