// import eventData from './eventData';

const events = (state=[], action) => {

  switch(action.type) {
    case "GET_EVENTS_SUCCESS":
      return [...action.payload];

    case "GET_EVENT_SUCCESS":
      return [...state, action.payload];

    case "UPDATE_EVENT_SUCCESS":
      // filter out old version and add updated version
      let updatedEvent = action.payload;
      let filtered = state.filter(e => e.id !== updatedEvent.id);
      return [...filtered, updatedEvent];

    case "ADD_EVENT_SUCCESS":
      let newEvent = action.payload;
      return [...state, newEvent];

    default:
      return state;
  }
}

export default events;


export const getEvents = (state) => state.events.events;
export const getEvent = (state, id) => state.events.events.find(e => String(e.id) === String(id));
