// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import customers from "./customers";
import updatingCustomer from "./updatingCustomer";

const customersReducer = combineReducers({
  customers,
  updatingCustomer,
});

export default customersReducer;

export { getCustomers, getCustomer} from './customers';
export { isUpdatingCustomer, getCustomerBeingUpdated, getCustomerUpdateError } from './updatingCustomer';
