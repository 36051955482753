import React, { Component, createContext } from "react";
import {getNoRoleAbilities} from '../../app/abilities'
import {createContextualCan} from '@casl/react';

const AbilityContext = createContext();

/** A Context Provider that will provide a casl ability instance */
class AbilityProvider extends Component {
  render() {
    return (
      <AbilityContext.Provider value={this.props.ability}>
        {this.props.children}
      </AbilityContext.Provider>
    );
  }
}

AbilityProvider.defaultProps = {
  ability: getNoRoleAbilities()
};

const AbilityConsumer = AbilityContext.Consumer;

/** Can
* A casl Can component bound to the AbilityContext
* Usage:
*   <Can I="create" a="user">
*     <Button>Add User</Button>
*   </Can>
*/
const Can = createContextualCan(AbilityContext.Consumer);

/** CanViewPath
* Helper component for hiding/showing components based on whether a user can
* navigate to a particular path. If path includes any query params thes will be
* omitted
*
* Usage:
*   <CanViewPath path="/dashboard/admin-only-stuff?foo=bar">
*     <Link to="/dashboard/admin-only-stuff?foo=bar">Go!</Link>
*   </CanViewPath>
*/
const CanViewPath = ({path, ...rest}) => {
  // omit any query params from path
  if (!path || path === '') {
    return rest.children;
  }
  let queryStart = path.indexOf('?');
  let resolvedPath = queryStart === -1 ? path : path.substring(0, queryStart);

  return <Can I="view" a={resolvedPath} {...rest} />
};

export {AbilityProvider, AbilityConsumer, Can, CanViewPath};
