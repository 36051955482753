export default (state, action) => {
  state = state || {};

  switch(action.type) {
    case 'SET_USER_PAY_OPTS_REQUEST':
      return {loading: true, userId: action.meta.userId};
    case 'SET_USER_PAY_OPTS_SUCCESS':
      return {loading: false};
    case 'SET_USER_PAY_OPTS_FAILURE':
      return {...state, loading: false, error: action.payload};
    default:
      return state;
  }
}
export const isSettingUserPayOpts = (state) => !!state.users.setUserPayOpts.loading;
export const getSetUserPayOptsUserId = (state) => state.users.setUserPayOpts.userId;
export const getSetUserPayOptsError = (state) => state.users.setUserPayOpts.error;
