import {combineReducers} from 'redux';
import _ from 'lodash';
import bookingDetailsReducer from './bookingDetailsReducer';
import bookingTransferReducer from './bookingTransferReducer';
import bookingPaymentsReducer from './bookingPaymentsReducer';

const listReducer = (list=[], action) => {
  switch(action.type){
    case "FETCH_BOOKINGS_SUCCESS": {
      return [...action.payload]
    }
    case "SET_BOOKING_TABLE_SUCCESS": {
      let table = action.payload;
      let replaceIdx = list.findIndex(b => b.id === table.bookingId);

      if(replaceIdx === -1)
        return list;

      let newList = list.slice(0, replaceIdx);
      let updatedBooking = {...list[replaceIdx], table: table};
      newList.push(updatedBooking);
      return newList.concat(list.slice(replaceIdx + 1, list.length));
    }

    case "UPDATE_BOOKING_ATTRIBS_SUCCESS": {
      let bookingId = action.meta.bookingId;
      let replaceIdx = list.findIndex(b => b.id === bookingId);

      if(replaceIdx === -1)
        return list;

      let newList = list.slice(0, replaceIdx);
      let updatedBooking = {...list[replaceIdx], attributes: action.payload};
      newList.push(updatedBooking);
      return newList.concat(list.slice(replaceIdx + 1, list.length));
    }

    default:
      return list;
  }
}

const currentPageReducer = (state=0, action) => {
  switch(action.type) {
    case "FETCH_BOOKINGS_REQUEST": {
      return action.meta.page;
    }

    default:
      return state;
  }
}

const sortedByReducer = (state = [], action) => {
  if (action.type === "FETCH_BOOKINGS_REQUEST")
      return action.meta.sortedBy;

  return state;
}

const whereReducer = (state = [], action) => {
  if (action.type === "FETCH_BOOKINGS_REQUEST")
      return action.meta.where || null;

  return state;
}

const includeReducer = (state = [], action) => {
  if (action.type === "FETCH_BOOKINGS_REQUEST")
      return action.meta.include || [];

  return state;
}

const totalBookingsReducer = (state=-1, action) => {
  switch(action.type){
    case "FETCH_BOOKINGS_COUNT_SUCCESS":
      return action.payload.count;
    default:
      return state;
  }
}

const isLoadingReducer = (state=false, action) => {
  switch(action.type) {
    case "FETCH_BOOKINGS_REQUEST":
      return true;
    case "FETCH_BOOKINGS_FAILED":
    case "FETCH_BOOKINGS_SUCCESS":
      return false;
    default:
      return state;
  }
}

const pageSizeReducer = (state=20, action) => {
  switch(action.type) {
    case "FETCH_BOOKINGS_REQUEST":
      return action.meta.pageSize || null;
    default:
      return state;
  }
}

const errorReducer = (state=null, action) => {
  switch(action.type) {
    case "FETCH_BOOKINGS_FAILED":
      return action.payload;
    case "FETCH_BOOKINGS_REQUEST":
    case "FETCH_BOOKINGS_SUCCESS":
      return null;
    default:
      return state;
  }
}

const bookingsListReducer = combineReducers({
  list:listReducer,
  currentPage: currentPageReducer,
  sortedBy: sortedByReducer,
  where: whereReducer,
  include: includeReducer,
  totalBookings: totalBookingsReducer,
  isLoading:isLoadingReducer,
  pageSize:pageSizeReducer,
  error:errorReducer
});

export default combineReducers({
  bookingsList: bookingsListReducer,
  bookingDetails: bookingDetailsReducer,
  transfer: bookingTransferReducer,
  payment: bookingPaymentsReducer,
})

// SELECTORS

export const getBookingsList = (state) => state.bookings.bookingsList.list;
export const getTotalPages = (state) => {
  let bl = state.bookings.bookingsList;
  return bl.totalBookings < 0 ? bl.totalBookings : Math.ceil(bl.totalBookings/bl.pageSize);
}
export const isLoadingBookings = (state) => state.bookings.bookingsList.isLoading;
export const getCurrentPage = (state) => state.bookings.bookingsList.currentPage;
export const getPageSize = (state) => state.bookings.bookingsList.pageSize;

export const getSearchFilters = (state) => {
  return {
    pageSize: getPageSize(state),
    page: getCurrentPage(state),
    sorted: state.bookings.bookingsList.sortedBy,
    where: state.bookings.bookingsList.where,
    include: state.bookings.bookingsList.include,
  }
}

export const isBookingFilterEqual = (state, where) => {
  let currentWhere = state.bookings.bookingsList.where;
  return _.isEqual(currentWhere, where);
}

export const isBookingSortedEqual = (state, sorted) => {
  let currentSorted = state.bookings.bookingsList.sortedBy;
  if (typeof currentSorted !== typeof sorted)
    return false;

  if(typeof currentSorted === 'string')
    return currentSorted === sorted;

  if (currentSorted.length !== sorted.length) {
    return false;
  }

  return currentSorted.every(s1 => sorted.find(s2 => _.isEqual(s1,s2)));
}

export {isLoadingBookingDetails, getBookingDetails, getBookingDetailsError, isUpdatingTickets, getTicketUpdateError} from './bookingDetailsReducer';
export {isTransferringBooking, getBookingTransfer, getBookingTransferError} from './bookingTransferReducer';
export {isLoadingPaymentRequest, isProcessingPayment, isSendingPaymentResponse, isAddingCashPayment, isMakingPayment,
  getPaymentRequest, getPaymentProcessResponse, getPaymentResponseResult,
  getPaymentRequestError, getPaymentProcessError, getPaymentResponseError,
  getCashPaymentError, paymentDidError, paymentDidCancel, wasPaymentSuccessful
} from './bookingPaymentsReducer';
