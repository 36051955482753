
export default (state, action) => {
  state = state || {agents:[], loading:false, error: null};
  switch(action.type) {
    case 'FETCH_AGENTS_REQUEST':
    return {...state, loading:true, error: null};
    case 'FETCH_AGENTS_SUCCESS':
    return {...state, agents: action.payload, loading:false, error: null};
    case 'FETCH_AGENTS_FAILURE':
      return {...state, loading:false, error: action.payload};
    default:
      return state;
  }
}

// Selectors

export const getAgentSellers = (state) => state.agents.agents;
export const isLoadingAgentSellers = (state) => state.agents.loading;
export const getAgentSellersError = (state) => state.agents.error;
