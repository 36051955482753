import { combineReducers } from 'redux';
import datesReducer from './dates';
import ticketOptionsReducer from './ticketOptions';
import newBookingReducer from './newBooking';
import doorSheetReducer from './doorSheet';

export default combineReducers({
  dates:datesReducer,
  ticketOptions:ticketOptionsReducer,
  newBooking:newBookingReducer,
  doorSheet:doorSheetReducer,
})

// Selectors

// Date Selectors
export {
  getAllSheduledDates,
  getScheduledDatesByEventId,
  getDateById,
  isLoadingSchedules,
  getScheduleError
} from './dates';

// Schedule Ticket Options Selectors
export {
  isLoadingScheduleTicketOptions,
  getScheduleTicketOptions,
  getScheduleTicketOptionsError,
  isLoadingScheduleTicketOverrides,
  getScheduleTicketOverrides,
  getScheduleTicketOverridesError,
  isUpdatingScheduleTicketOverride,
  getUpdatingScheduleTicketOptionId,
} from './ticketOptions';

// New Booking Selectors
export {
  getBookingStep,
  getNewBooking,
  getNewBookingError,
  getPaymentError,
  getEmailRequest,
  getCCDetails,
} from './newBooking';

// Door Sheet Selectors
export {
  getCheckInStatus,
  getCheckInStatusError,
  isLoadingCheckInStatus,
} from './doorSheet';
