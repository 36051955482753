const initState = {user:null, error:null };

const addUser = (state=initState, action) => {
  switch(action.type) {
    case "ADD_USER_REQUEST":
      return {...state, user:action.payload.user};

    case "ADD_USER_FAILURE":
      return {...state, error:action.error};

    case "ADD_USER_SUCCESS":
      return {...state, user:null, error:null};

    default:
      return state;
  }
};

export default addUser;

// Selectors

export const isAddingUser = (state) => state.users.addUser.user != null;
export const getUserBeingAdded = (state) => state.users.addUser.user;
