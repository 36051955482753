export function boipaIFrameReducer(state, action) {
    state = state || {show: false, apiLoaded: false};
  
    switch(action.type) {
      case "BOIPA_JS_API_LOADED":
        return {
          ...state,
          apiLoaded: true,
        }
      case "BOIPA_JS_API_UNLOADED":
        return {
          ...state,
          apiLoaded: false,
        }
      default:
        return state;
    }
  }
  