export default function (state, action) {
  state = state || {options: [], overrides:[], loading: false, error: null};

  switch(action.type) {
    case "FETCH_SCHEDULE_TICKET_OPTIONS_REQUEST":
      return {...state, loading: true, error: null};
    case "FETCH_SCHEDULE_TICKET_OPTIONS_SUCCESS":
      return {...state, loading: false, error: null, options: action.payload};
    case "FETCH_SCHEDULE_TICKET_OPTIONS_FAILURE":
      return {...state, loading: false, error: action.payload};

    case "FETCH_SCHEDULE_TICKET_OVERRIDES_REQUEST":
      return {...state, loadingOverrides: true, overridesError: null};
    case "FETCH_SCHEDULE_TICKET_OVERRIDES_SUCCESS":
      return {...state, loadingOverrides: false, overridesError: null, overrides: action.payload};
    case "FETCH_SCHEDULE_TICKET_OVERRIDES_FAILURE":
      return {...state, loadingOverrides: false, overridesError: action.payload};

    case "SET_SCHEDULE_TICKET_OVERRIDE_REQUEST":
      return {...state, updatingOverride: true, updatingOverrideError: null, updatingMetaData: action.meta};
    case "SET_SCHEDULE_TICKET_OVERRIDE_SUCCESS":
      let updatedOverrides;
      let {isDeleting, ticketOptionId} = state.updatingMetaData;
      // remove from list if it was just deleted
      if (isDeleting) {
        updatedOverrides = state.overrides.filter(o => o.ticketOptionId !== ticketOptionId);
      } else {
        // otherwise add to list
        updatedOverrides = [...state.overrides, action.payload];
      }
      return {...state, overrides: updatedOverrides, updatingOverride: false, updatingOverrideError: null, updatingMetaData: null};
    case "SET_SCHEDULE_TICKET_OVERRIDE_FAILURE":
      return {...state, updatingOverride: false, updatingOverrideError: action.payload};

    default:
      return state;
  }
}

// Selectors
const ticketOptions = (state) => state.schedule.ticketOptions;

export const isLoadingScheduleTicketOptions = (state) => ticketOptions(state).loading;
export const getScheduleTicketOptions = (state) => ticketOptions(state).options;
export const getScheduleTicketOptionsError = (state) => ticketOptions(state).error;

export const isLoadingScheduleTicketOverrides = (state) => ticketOptions(state).loadingOverrides;
export const getScheduleTicketOverrides = (state) => ticketOptions(state).overrides;
export const getScheduleTicketOverridesError = (state) => ticketOptions(state).overridesError;

export const isUpdatingScheduleTicketOverride = (state) => ticketOptions(state).updatingOverride;
export const getUpdatingScheduleTicketOptionId = (state) => (ticketOptions(state).updatingMetaData || {}).ticketOptionId;
