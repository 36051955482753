export default (state, action) => {
  state = state || {};

  switch(action.type) {
    case 'SET_USER_ROLE_REQUEST':
      return {loading: true, userId: action.meta.userId, role: action.meta.role};
    case 'SET_USER_ROLE_SUCCESS':
      return {loading: false};
    case 'SET_USER_ROLE_FAILURE':
      return {...state, loading: false, error: action.payload};
    default:
      return state;
  }
}

export const isSettingUserRole = (state) => !!state.users.setUserRole.loading;
export const getSetUserRoleUserId = (state) => state.users.setUserRole.userId;
export const getSetUserRoleError = (state) => state.users.setUserRole.error;
