import {
  getPaymentRequestError,
  getPaymentProcessError,
  getPaymentResponseError,
} from '../bookings';
export default function newBookingReducer(state, action) {
  if(state==null) {
    state = {
      bookingStep:'none',
      error:null
    }
  }

  switch(action.type) {
      case "NEW_BOOKING_START":
        return {
          bookingStep: 'booking-started',
          paymentMade: false,
          emailSent: false,
          emailError: null,
        };

      case "NEW_BOOKING_CANCELLED":
      case "NEW_BOOKING_FINISHED":
        return {
          error: null,
          newBooking: null,
          bookingStep: 'none'
        };

      case "SUBMIT_BOOKING_FORM":
        return {
          ...state,
          bookingStep: getNextBookingStep(state),
          scheduleId: action.payload.scheduleId,
          bookingData: action.payload.bookingData,
          paymentMethod: action.payload.paymentMethod,
          emailRequest: action.payload.emailRequest,
          cc: action.payload.cc,
        };

      case "CREATE_NEW_BOOKING_REQUEST":
        return {
          ...state,
          newBooking: null,
          creationError: null,
        }

      case "CREATE_NEW_BOOKING_FAILURE":
        return {
          ...state,
          creationError: action.payload,
          bookingStep: 'booking-started'
        }

      case "CREATE_NEW_BOOKING_SUCCESS":
        return {
          ...state,
          bookingStep: getNextBookingStep(state),
          newBooking: action.payload,
          creationError: null
        };

      // Payment Results

      case "UPDATE_BOOKING_PAYMENT_STATUS_SUCCESS":
        return {...state, bookingStep: getNextBookingStep(state)};

      // record email results
      case "SEND_BOOKING_EMAIL_SUCCESS":
        return {
          ...state,
          bookingStep: getNextBookingStep(state),
          emailSent: true
        };

      case "SEND_BOOKING_EMAIL_FAILURE":
        return {
          ...state,
          // complete booking even though email did not send
          bookingStep: getNextBookingStep(state),
          emailSent: false,
          emailError: action.payload,
        };

      default:
        return state;
  }
}

/** Returns the name of the next step in the booking process */
function getNextBookingStep(state) {
  switch(state.bookingStep) {
    case 'booking-started':
      return 'create-booking';

    case 'create-booking':
      if (state.paymentMethod === 'credit-card')
        return 'pay-credit-card';
      else if (state.emailRequest)
        return 'send-email';
      else
        return 'booking-completed';

    case 'pay-credit-card':
      if (state.emailRequest)
        return 'send-email';
      else
        return 'booking-completed';

    case 'send-email':
      return 'booking-completed';

    default:
      return 'booking-started';
  }
}

export function getBookingStep(state) {
  return state.schedule.newBooking.bookingStep;
}

export function getNewBooking(state) {
  return state.schedule.newBooking.newBooking;
}

export function getEmailRequest(state) {
  return state.schedule.newBooking.emailRequest;
}

export function getCCDetails(state) {
  return state.schedule.newBooking.cc;
}

export function getNewBookingError(state) {
  return state.schedule.newBooking.creationError;
}

export function getPaymentError(state) {
  return getPaymentRequestError(state) ||
         getPaymentProcessError(state) ||
         getPaymentResponseError(state);
}
