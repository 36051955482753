// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import agentsReducer from "./agents";
import authReducer from "./auth";
import bookingsReducer from "./bookings";
import calenderReducer from "./calenderReducer";
import emailReducer from "./email/";
import eventsReducer from "./events/"
import contactsReducer from "./contacts/";
import scheduleReducer from "./schedule/"
import usersReducer from "./users/";
import statisticsReducer from "./statistics/";
import customersReducer from "./customers/";
import tourOperatorsReducer from "./tourOperators/";
import { boipaIFrameReducer } from "./bookings/boipaIFrameReducer";

const rootReducer = combineReducers({
   agents: agentsReducer,
   auth: authReducer,
   bookings: bookingsReducer,
   calender: calenderReducer,
   emailApp: emailReducer,
   events: eventsReducer,
   contactApp: contactsReducer,
   schedule: scheduleReducer,
   users: usersReducer,
   stats: statisticsReducer,
   customers: customersReducer,
   tourOperators: tourOperatorsReducer,
   boipaIFrame: boipaIFrameReducer
});

export default rootReducer;
