const initState = {customerId:null, customer:null, error:null };

const updatingCustomer = (state=initState, action) => {
   switch (action.type) {
      case "UPDATE_CUSTOMER_REQUEST":
         return {...state, customerId:action.payload.customerId, customer:action.payload.customer };

      case "UPDATE_CUSTOMER_FAILURE":
        console.log(action);
        return {...state, error:action.payload};

      case "UPDATE_CUSTOMER_SUCCESS":
        return {...state, customerId:null, customer:null, error:null};

      default:
         return state;
   }
};

export default updatingCustomer;

export const isUpdatingCustomer = (state, customerId) => state.customers.updatingCustomer.customerId === customerId;
export const getCustomerBeingUpdated = (state) => state.customers.updatingCustomer.customer;
export const getCustomerUpdateError = (state) => state.customers.updatingCustomer.error;
