import {combineReducers} from 'redux';


function paymentStep(state, action)
{
  switch (action.type) {
    case "FETCH_PAYMENT_REQUEST_REQUEST":
      return 'token-request';
    case "PROCESS_BOOKING_PAYMENT_START":
      return 'process-card';
    case "UPDATE_BOOKING_PAYMENT_STATUS_REQUEST":
      return 'update-booking-payment';
    case "UPDATE_BOOKING_PAYMENT_STATUS_SUCCESS":
      return 'completed';
    
    case "FETCH_PAYMENT_REQUEST_FAILURE":
    case "PROCESS_BOOKING_PAYMENT_CANCELLED":
    case "PROCESS_BOOKING_PAYMENT_ERROR":
    case "UPDATE_BOOKING_PAYMENT_STATUS_FAILED":
    case "RESET_PAYMENT_STATE":
      return 'none';
    default:
      return state || 'none';
  }
}

/** 
 * State for payment token request
 * Fetch token/data needed to make payment using 
 * hosted payment page (elavon) or embedded card form (boipa)
 */
function paymentRequestReducer(state, action) {
  state = state || {loading:false, error: null, request: null};
  switch(action.type) {
    case "RESET_PAYMENT_STATE":
      return {loading: false, error: null, request: null};
    case "FETCH_PAYMENT_REQUEST_REQUEST":
      return {loading: true, error: null, request: null};
    case "FETCH_PAYMENT_REQUEST_SUCCESS":
      return {loading: false, error: null, request: action.payload};
    case "FETCH_PAYMENT_REQUEST_FAILURE":
      return {loading: false, request: null, error: action.payload};

    default:
      return state;
  }
}

/**
 * State for payment process
 * Tracks progress of 3rd party payment form
 */
function processPaymentReducer(state, action) {
  state = state || {};
  switch(action.type) {
    case "RESET_PAYMENT_STATE":
      return {loading: false, error: null, response: null, cancelled: false};
    case "PROCESS_BOOKING_PAYMENT_START":
      return {loading: false, error: null, response: null, cancelled: false, bookingId: action.payload.bookingId};
    case "PROCESS_BOOKING_PAYMENT_SUCCESS":
      return {loading: false, error: null, response: action.payload.response, cancelled: false};
    case "PROCESS_BOOKING_PAYMENT_CANCELLED":
      return {loading: false, response: null, error: null, cancelled: true};
    case "PROCESS_BOOKING_PAYMENT_ERROR":
      return {loading: false, response: null, error: action.payload, cancelled:false};
    default:
      return state;
  }
}


function paymentResponseReducer(state, action) {
  state = state || {};
  switch(action.type) {
    case "RESET_PAYMENT_STATE":
      return {loading: false, error: null, result: null};
    case "UPDATE_BOOKING_PAYMENT_STATUS_REQUEST":
      return {loading: true, error: null, result: null};
    case "UPDATE_BOOKING_PAYMENT_STATUS_SUCCESS":
      return {loading: false, error: null, result: action.payload};
    case "UPDATE_BOOKING_PAYMENT_STATUS_FAILED":
      return {loading: false, result: null, error: action.payload};
    default:
      return state;
  }
}

function cashPaymentReducer(state, action) {
  state = state || {};
  switch(action.type) {
    case "RESET_PAYMENT_STATE":
      return {loading: false, error: null, result: null};
    case "ADD_CASH_PAYMENT_REQUEST":
      return {loading: true, error: null, result: null};
    case "ADD_CASH_PAYMENT_SUCCESS":
      return {loading: false, error: null, result: action.payload};
    case "ADD_CASH_PAYMENT_FAILURE":
      return {loading: false, result: null, error: action.payload};
    default:
      return state;
  }
}

export default combineReducers({
  request: paymentRequestReducer,
  process: processPaymentReducer,
  response: paymentResponseReducer,
  cash: cashPaymentReducer,
  step: paymentStep,
});

// Selectors
const getRequest = (state) => state.bookings.payment.request;
const getProcess = (state) => state.bookings.payment.process;
const getResponse = (state) => state.bookings.payment.response;
const getCashResponse = (state) => state.bookings.payment.cash;

// isLoading
export const isLoadingPaymentRequest = (state) => getRequest(state).loading;
export const isProcessingPayment = (state) => getProcess(state).loading;
export const isSendingPaymentResponse = (state) => getResponse(state).loading;
export const isAddingCashPayment = (state) => getCashResponse(state).loading;
export const isMakingPayment = (state) => (
  isLoadingPaymentRequest(state) ||
  isProcessingPayment(state) ||
  isSendingPaymentResponse(state) ||
  isAddingCashPayment(state)
);

// getters
export const getPaymentStep = (state) => state.bookings.payment.step;

export const getPaymentRequest = (state) => getRequest(state).request;
export const getPaymentProcessResponse = (state) => getProcess(state).response;
export const getPaymentResponseResult = (state) => {
  return getResponse(state).result || getCashResponse(state).result;
}
export const wasPaymentSuccessful = (state) => {
  let response = getPaymentProcessResponse(state);
  // Elavon success = 00 
  return response && response.RESULT === '00';
};

// get error
export const getPaymentRequestError = (state) => getRequest(state).error;
export const getPaymentProcessError = (state) => getProcess(state).error;
export const getPaymentResponseError = (state) => getResponse(state).error;
export const getCashPaymentError = (state) => getCashResponse(state).error;
export const paymentDidError = (state) => (Boolean( // cast to boolean
  getPaymentRequestError(state) ||
  getPaymentProcessError(state) ||
  getPaymentResponseError(state) ||
  getCashPaymentError(state)
));
export const paymentDidCancel = (state) => getProcess(state).cancelled;
