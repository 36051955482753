
export default (state, action) => {
  state = state || {tourOperators:[], loading:false, error: null};
  switch(action.type) {
    case 'FETCH_TOUR_OPERATORS_REQUEST':
    return {...state, loading:true, error: null};
    case 'FETCH_TOUR_OPERATORS_SUCCESS':
    return {...state, list: action.payload, loading:false, error: null};
    case 'FETCH_TOUR_OPERATORS_FAILURE':
      return {...state, loading:false, error: action.payload};
    default:
      return state;
  }
}

// Selectors

export const getTourOperators = (state) => state.tourOperators.list;
export const isLoadingTourOperators = (state) => state.tourOperators.loading;
export const getTourOperatorsError = (state) => state.tourOperators.error;
