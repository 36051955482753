
const users = (state={list: []}, action) => {
  switch(action.type) {
    case "FETCH_USERS_REQUEST":
      return {...state, loading: true, error: null};

    case "FETCH_USERS_SUCCESS":
      return {...state, loading: false, error: null, list: action.payload};

    case "FETCH_USERS_FAILURE":
      return {...state, loading: false, error: action.payload};

    case "CREATE_USER_SUCCESS":
      // roles will not be set after adding new user, add as empty array
      let newUser = {roles:[], paymentOptions:[], ...action.payload};
      return {...state, list: [...state.list, newUser]};

    case "FETCH_USER_BY_ID_SUCCESS":
      let loadedUser = action.payload;
      let idx = state.list.findIndex(user => user.id === loadedUser.id);
      let newList = state.list.concat();

      // push to new list or replace if already exists in list
      if(idx === -1) {
        newList.push(loadedUser);
      } else {
        newList[idx] = loadedUser;
      }

      return {...state, list: newList};

    // Delete User

    case "DELETE_USER_SUCCESS":
      // remove user from list
      let userId = action.meta && action.meta.userId;
      if(!userId)
        return state;

      let updatedList = state.list.filter(user => user.id !== userId);
      return {...state, list: updatedList};

    default:
      return state;
  }
}

export default users;

export const getUsers = (state) => state.users.users.list;
export const isLoadingUsers = (state) => state.users.users.loading;
export const getUserById = (state, userId) => state.users.users.list.find(u => String(u.id) === String(userId));
