// import external modules
import { combineReducers } from "redux";
// import internal(own) modules
import events from "./events";
import ticketOptions from "./ticketOptions";

const eventsReducer = combineReducers({
  events,
  ticketOptions,
});

export default eventsReducer;

export { getEvents, getEvent } from './events';
export { getTicketOptions, isLoadingTicketOptions } from './ticketOptions';
