
export default function(state, action) {
  state = state || {};

  switch(action.type) {
    case "FETCH_SCHEDULE_CHECK_IN_STATUS_REQUEST":
      return {...state, loading:true, error: null};
    case "FETCH_SCHEDULE_CHECK_IN_STATUS_SUCCESS":
      return {...state, loading:false, status: action.payload};
    case "FETCH_SCHEDULE_CHECK_IN_STATUS_FAILURE":
      return {...state, loading:false, error: action.payload};
    default:
      return state;
  }
}

// Selectors

export const getCheckInStatus = (state) => state.schedule.doorSheet.status;
export const getCheckInStatusError = (state) => state.schedule.doorSheet.error;
export const isLoadingCheckInStatus = (state) => state.schedule.doorSheet.loading;
