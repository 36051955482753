import { combineReducers } from "redux";
import loginReducer from './loginReducer';
import {defineAbilitiesFor} from '../../../app/abilities';
let user = JSON.parse(localStorage.getItem('user'));
let initialState = user ? user : {};
let abilities = defineAbilitiesFor(initialState.user || {});

/** Token Reducer */
const tokenReducer = (state=initialState, action) => {
  switch(action.type) {
    case "LOGIN_SUCCESS":
      localStorage.setItem('user', JSON.stringify(action.payload));
      return {...action.payload};

    case "LOGOUT_SUCCESS":
    case "LOGOUT_FAILURE":
      // clear token state on logout
      return {};
    case "SET_MY_SUBUSERS_SUCCESS":
      let user = {...state.user, subUsers: action.payload};
      let newState = {...state, user};
      localStorage.setItem('user', JSON.stringify(newState));

      return newState;

    default:
      return state;
  }
}

const abilitiesReducer = (state = abilities, action) => {
  switch(action.type) {
    case "LOGIN_SUCCESS":
      return defineAbilitiesFor(action.payload.user);
    default:
      return state;
  }
}

const paymentOptionsReducer = (state = [], action) => {
  switch(action.type) {
    case "FETCH_PAYMENT_OPTIONS_SUCCESS":
      return action.payload;
    default:
      return state;
  }
}

/** Combine Reducer */
export default combineReducers({
  token:tokenReducer,
  abilities: abilitiesReducer,
  login:loginReducer,
  paymentOptions: paymentOptionsReducer,
});

// Selector Functions

export const isAuthenticated = (state) => {
  if(state.auth.token.id == null)
    return false;

  // check if token is expired
  const ttl = state.auth.token.ttl;
  const created = new Date(state.auth.token.created);
  return created.getTime() + (ttl * 1000) > Date.now();
}

export const getAccessToken = (state) => {
  return state.auth.token.id;
}

export const getMyId = (state) => isAuthenticated(state) ? state.auth.token.userId : null;
export const userIsMe = (state, userId) => isAuthenticated(state) ? state.auth.token.userId === userId : false;
export const getAuthHeader = (state) => ({Authorization: getAuthorizationToken(state)});
export const getAuthorizationToken = (state) => getAccessToken(state);
export const getLoggedInUser = (state) => isAuthenticated(state) ? state.auth.token.user : null;
export const getAbilities = (state) => isAuthenticated(state) ? state.auth.abilities : null;
export const canViewRoute = (state, path) => isAuthenticated(state) && state.auth.abilities.can('view', path);
export const getUserPaymentOptions = (state) => state.auth.paymentOptions.map(opt => opt.option);
export const userHasRole = (state, roleName) => (
  isAuthenticated(state) && getLoggedInUser(state).roles.some((r) => r.name === roleName)
);
// export selector methods from login reducer
export * from "./loginReducer";
