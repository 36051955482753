function bookingTransferReducer(state, action) {
  state = state || {};
  switch(action.type) {
    case "FETCH_BOOKING_DETAILS_REQUEST": {
      if(action.meta.bookingId === state.bookingId)
        return state;

      return {...state, bookingId: action.meta.bookingId, bookingTransfer: null};
    }
    case "TRANSFER_BOOKING_REQUEST":
     return {...state, bookingId: action.meta.bookingId, loading: true, error: null};

    case "TRANSFER_BOOKING_SUCCESS":
     return {...state, bookingTransfer: action.payload, loading: false};

    case "TRANSFER_BOOKING_FAILURE":
     return {...state, loading: false, error: action.payload};

    default:
      return state;
  }
}

export default bookingTransferReducer;

// Selectors

export const isTransferringBooking = (state) => state.bookings.transfer.loading;
export const getBookingTransferError = (state) => state.bookings.transfer.error;
export const getBookingTransfer = (state) => state.bookings.transfer.bookingTransfer;
