
export default function statisticsReducer(state, action) {
  state = state || {loading:false, data: {}};
  switch (action.type) {
    case 'FETCH_STASTISTICS_REQUEST':
      return {...state, loading:true, error: null};
    case 'FETCH_STASTISTICS_COMPLETED':
      return {...state, loading:false, data: action.payload};
    case 'FETCH_STASTISTICS_FAILURE':
      return {...state, loading:false, error: action.payload}
    default:
      return state;
  }
}

// Selectors

export const getStats = (state) => state.stats.data;
export const isLoadingStats = (state) => state.stats.loading;
export const getStatsError = (state) => state.stats.error;
