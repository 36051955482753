import { RSAA, getJSON } from 'redux-api-middleware';
import {normalize} from 'normalizr';

const NO_AUTH_TOKEN = (store) => "";

/** Api Helper allows RSAA request to omit the api url and use a shorthand */
export function createApiHelper(config) {

  for(var id in config) {
    if(config[id].url == null) {
      throw new Error("API url must be set");
    }

    if(config[id].authTokenGetter !== null){
      if(typeof config[id].authTokenGetter !== 'function'){
        throw new Error("authTokenGetter must be a function");
      }
    }
    else {
      config[id].authTokenGetter = NO_AUTH_TOKEN;
    }

  }

  return store => next => action => {

    let apiCall = null;
    for(let apiRoot in config) {
      if(action[apiRoot] != null) {
        apiCall = apiRoot;
        break;
      }

    }

    if(!apiCall) {
      return next(action);
    }

    let api = config[apiCall];
    let {normalizeSchema, endpoint, ...rsaa} = action[apiCall];

    // TODO: check if endpoint already fully formed
    endpoint = endpoint.startsWith("/") ? endpoint.substring(1) : endpoint;

    // Add Authorization header
    let headers = rsaa.headers ? {...rsaa.headers} : {};
    headers.Authorization = api.authTokenGetter(store.getState());
    rsaa.headers = headers;

    if(normalizeSchema) {
      rsaa.types = [
        rsaa.types[0],
        normalizeResponse(rsaa.types[1],normalizeSchema),
        rsaa.types[2],
      ]
    }

    let fullEndpoint = `${api.url}/${endpoint}`;
    let newAction = {
      ...action,
      [RSAA]: {
        ...rsaa,
        endpoint:fullEndpoint
      }
    }

    return next(newAction);
  }
}

/** normalizeResponse
* Creates a success type descriptor that will normalize the data returned from a request using the given schema.
* Success type descriptor - https://www.npmjs.com/package/redux-api-middleware#success-type-descriptors
*/
function normalizeResponse(type, schema) {
  let payloadHandler = (action, state, res) => {
    return getJSON(res).then((json) => normalize(json, schema));
  }

  if(typeof type === "string") {
    return {type:type, payload:payloadHandler};
  }
  else {
    return {...type,payload:payloadHandler};
  }
}
