import { AbilityBuilder } from '@casl/ability'
/**
* Returns a casl ability object that defines what actions the given user can do.
* Ability actions inlude the CRUD verbs 'create', 'read', 'update', 'delete' and
* also 'view' which is used for controlling routes.
* @param user The user in question. Must contain the users roles.
* @return {Ability}
*/
export function defineAbilitiesFor(user) {
  let roles = user.roles || [];
  // assuming that a user only has one role for now
  let mainRole = roles.length > 0 ? roles[0] : 'none';
  let roleName = mainRole.name;

  switch(roleName) {
    case 'admin':
      return getAdminAbilities(user);
    case 'sales':
      return getSalesAbilities(user);
    case 'staff':
      return getStaffAbilities(user);
    case 'ticket-agent':
      return getTicketAgentAbilities(user);
    default:
      return getNoRoleAbilities();
  }
}

function getAdminAbilities(user) {
  return AbilityBuilder.define((allow, deny) => {
    // routes
    allow('view', 'all');
    deny('view', '/agent-dashboard');
    deny('view', '/agent-schedule-select');
    deny('view', '/agent-schedule');
    deny('view', '/agent-bookings');
    if (user.hasSubUsers)
      allow('view', '/sub-users');
    else
      deny('view', '/sub-users');

    // data
    allow('create', 'all');
    allow('read', 'all');
    allow('update', 'all');
    allow('delete', 'all');
  });
}

function getSalesAbilities(user) {
  return AbilityBuilder.define((allow, deny) => {
    // routes
    allow('view', 'all');
    deny('view', '/dashboard');
    deny('view', '/events');
    deny('view', '/users');
    deny('view', '/agent-dashboard');
    deny('view', '/tour-operators');
    if (user.hasSubUsers)
      allow('view', '/sub-users');
    else
      deny('view', '/sub-users');


    // data
    allow('read', 'all');
    allow('create', 'all');
    allow('update', 'all');

    deny('delete', 'payment');
    deny('update', 'event');
    deny('update', 'ticketOptionOverride');
    deny('create', 'event');
    deny('create', 'schedule');
    deny('create', 'ticketOption');
    deny('update', 'ticketOption');
    deny('read', 'statistics');
  })
}

function getStaffAbilities(user) {
  return AbilityBuilder.define((allow, deny) => {
    // routes
    allow('view', 'all');
    deny('view', '/dashboard');
    deny('view', '/events');
    deny('view', '/users');
    deny('view', '/agent-dashboard');
    deny('view', '/tour-operators');
    if (user.hasSubUsers)
      allow('view', '/sub-users');
    else
      deny('view', '/sub-users');

    allow('read', 'all');
    deny('read', 'advancedBookingSearch');
    allow('update', 'schedule', 'timeSlots');
    deny('update', 'schedule', ['maxTickets', 'enabled']);
  })
}

function getTicketAgentAbilities(user) {
  return AbilityBuilder.define((allow, deny) => {
    allow('view', 'all');

    allow('view', '/agent-dashboard');
    allow('view', '/agent-schedule');
    allow('view', '/agent-schedule-select');
    allow('view', '/agent-bookings');


    deny('view', '/dashboard');
    deny('view', '/schedule');
    deny('view', '/schedule-select');
    deny('view', '/bookings');
    deny('view', '/events');
    deny('view', '/users');
    deny('view', '/agent-sellers');
    deny('view', '/tour-operators');
    if (user.hasSubUsers)
      allow('view', '/sub-users');
    else
      deny('view', '/sub-users');

    deny('read', 'doorsheet');
    deny('read', 'ticketTotals');
    deny('read', 'bookings');
    deny('create', 'booking');
    deny('read', 'schedule');
    allow('create', 'agentBooking');
    allow('read', 'agentBookings');
    allow('read', 'agentSchedule');

    deny('read', 'advancedBookingSearch');

    deny('create', 'bookingTransfer');
    deny('read', 'customerDetails');
  })
}

/** Default Abilities */
export function getNoRoleAbilities() {
  return AbilityBuilder.define((allow, deny) => {
    allow('view', '/');
    allow('view', '/schedule-select');
    deny('view', '/agent-dashboard');

    deny('read', 'all');
  })
}
