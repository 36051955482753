
const initState = {isLoggingIn:false, error:null};

export default (state = initState, action) => {
  switch(action.type) {
    case "LOGIN_REQUEST":
      return {...state, isLoggingIn:true, error:null}

    case "LOGIN_FAILURE":
      return {...state, isLoggingIn:false, error:action.error}

    case "LOGIN_SUCCESS":
      return {...state, isLoggingIn:false, error:null}

    default:
      return state;
  }
}

// Selector Methods

export const isLoggingIn = (state) => state.auth.login.isLoggingIn;
export const getLoginError = (state) => state.auth.login.error;
