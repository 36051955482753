import { combineReducers } from "redux";

import users from "./users";
import addUser from "./addUser";
import setUserRole from "./setUserRole";
import setUserPayOpts from "./setUserPayOpts";

const usersReducer = combineReducers({
  users,
  addUser,
  setUserRole,
  setUserPayOpts,
});

export default usersReducer;

export {getUsers, getUserById} from "./users";
export {isAddingUser, getUserBeingAdded} from "./addUser";
export {isSettingUserRole, getSetUserRoleUserId, getSetUserRoleError} from './setUserRole';
export {isSettingUserPayOpts, getSetUserPayOptsUserId, getSetUserPayOptsError} from './setUserPayOpts';
