function bookingDetailsReducer(state, action) {
    if(state == null) state = {};

    switch(action.type) {
      case "FETCH_BOOKING_DETAILS_REQUEST":
        let newState = {...state, loading:true, error:null}
        // keep current data if reloading same booking
        if(newState.bookingId !== action.meta.bookingId){
          newState.bookingId = action.meta.bookingId;
          newState.booking = null;
        }
        return newState;
      case "FETCH_BOOKING_DETAILS_COMPLETED":
        return {...state, loading:false, booking: action.payload};
      case "FETCH_BOOKING_DETAILS_FAILURE":
        return {...state, loading:false, error: action.payload};

      case "UPDATE_BOOKING_TICKETS_REQUEST":
        return {...state, isUpdatingTickets:true, ticketUpdateError: null}

      case "UPDATE_BOOKING_TICKETS_SUCCESS":
        return {...state, isUpdatingTickets:false, ticketUpdateError: null}

      case "UPDATE_BOOKING_TICKETS_FAILURE":
        return {...state, isUpdatingTickets:false, ticketUpdateError: action.payload}

      case "CANCEL_BOOKING_REQUEST":
        return {
          ...state,
          loading: action.meta.bookingId === state.bookingId,
          error: null,
        };
      case  "CANCEL_BOOKING_FAILURE":
        return {...state, loading:false, error: action.payload}
      case  "CANCEL_BOOKING_SUCCESS":
        // ignore if not the current booking
        if(String(state.bookingId) !== String(action.payload.id))
          return state;

        // merge result with current booking instance
        return {...state, loading:false, error: false,
          booking: {...state.booking, ...action.payload},
        };
      case "UPDATE_CUSTOMER_SUCCESS":
        return {
          ...state,
          booking: {
            ...state.booking,
            customer: action.payload
          },
        };
      default:
        return state;
    }
}

export default bookingDetailsReducer;

// Selectors

export const isLoadingBookingDetails = (state) => state.bookings.bookingDetails.loading;
export const getBookingDetails = (state) => state.bookings.bookingDetails.booking;
export const getBookingDetailsError = (state) => state.bookings.bookingDetails.error;

export const isUpdatingTickets = (state) => state.bookings.bookingDetails.isUpdatingTickets;
export const getTicketUpdateError = (state) => state.bookings.bookingDetails.ticketUpdateError;
