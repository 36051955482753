// import external modules
import React, { Suspense, lazy } from "react";
import ReactDOM from "react-dom";
import { Provider, connect } from "react-redux";
import { QueryClient, QueryClientProvider} from "react-query";


// import internal(own) modules
import registerServiceWorker from "./registerServiceWorker";
import { store } from "./redux/storeConfig/store";

import "font-awesome/css/font-awesome.min.css";

import "./index.scss";
import Spinner from "./components/spinner/spinner";

import {AbilityProvider} from './utility/context/abilityContext';
import {getAbilities} from './redux/reducers/auth/';
import { ReactQueryDevtools } from 'react-query/devtools'

const LazyApp = lazy(() => import("./app/app"));

// set ability context from ability in redux store
const mapStateToProps = (state) => ({ability: getAbilities(state)});
const ConnectedAbilityProvider = connect(mapStateToProps)(AbilityProvider);

const queryClient = new QueryClient();

ReactDOM.render(
   <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <ConnectedAbilityProvider>
        <Suspense fallback={<Spinner />}>
          <LazyApp />
        </Suspense>
      </ConnectedAbilityProvider>
      {/* <ReactQueryDevtools/> */}
    </QueryClientProvider>
   </Provider>,
   document.getElementById("root")
);
// registerServiceWorker();
