import { createStore, applyMiddleware, compose } from "redux";
import createDebounce from "redux-debounced";
import thunk from "redux-thunk";
import rootReducer from "../reducers/rootReducer";
import {createApiHelper} from "../middleware/apiHelper";
import {getAuthorizationToken} from "../reducers/auth"
import { apiMiddleware } from 'redux-api-middleware';


const apiHelper = createApiHelper({
  API:{
    url:process.env.REACT_APP_API_URL,
    authTokenGetter: getAuthorizationToken
  }
});

const middlewares = [thunk, createDebounce(), apiHelper, apiMiddleware];

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
   rootReducer,
   {},
   composeEnhancers(applyMiddleware(...middlewares))
);

export { store };
